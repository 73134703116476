import React from 'react'

import PropTypes from 'prop-types'

import './testimonial-card.css'

const TestimonialCard = (props) => {
  return (
    <div
      className={`testimonial-card-testimonial-card ${props.rootClassName} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="testimonial-card-image"
      />
      <div className="testimonial-card-testimonial">
        <span className="testimonial-card-text">{props.name}</span>
        <span className="testimonial-card-text1">{props.role}</span>
      </div>
    </div>
  )
}

TestimonialCard.defaultProps = {
  image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  quote:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.  Nam pellentesque nulla leo, sagittis vehicula sem commodo nec.',
  role: 'SOFTWARE ENGINEER',
  picture_alt: 'profile',
  name: 'Jane Doe',
  rootClassName: '',
  picture_src:
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&h=1200',
  image_alt: 'image',
}

TestimonialCard.propTypes = {
  image_src: PropTypes.string,
  quote: PropTypes.string,
  role: PropTypes.string,
  picture_alt: PropTypes.string,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
  picture_src: PropTypes.string,
  image_alt: PropTypes.string,
}

export default TestimonialCard
