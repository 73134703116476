import React from 'react'

import PropTypes from 'prop-types'

import './primary-button.css'

const PrimaryButton = (props) => {
  return (
    <div className={`primary-button-container ${props.rootClassName} `}>
      <button type="button" className="primary-button-button Headline6" onClick={props.onClick}>
        {props.button}
      </button>
    </div>
  )
}

PrimaryButton.defaultProps = {
  rootClassName: '',
  button: 'Button',
}

PrimaryButton.propTypes = {
  rootClassName: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default PrimaryButton
