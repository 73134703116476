import React from 'react'

import { Helmet } from 'react-helmet'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import PrimaryButton from '../components/primary-button'
import FeatureCard from '../components/feature-card'
import TestimonialCard from '../components/testimonial-card'
import StatsCard from '../components/stats-card'
import './home.css'

const Home = (props) => {

  const testimonialItems = [
    <TestimonialCard
      picture_src="https://images.unsplash.com/photo-1557053910-d9eadeed1c58?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
      rootClassName="rootClassName2"
      image_src="/assets/testimoni/endra-700h.png"
      name="Endra Bagus Setiyanto"
      role="PIYUNGAN - KUD TRIUPOYO"
    ></TestimonialCard>,
    <TestimonialCard
      picture_src="/assets/testimoni/andar-500h.png"
      rootClassName="rootClassName"
      image_src="/assets/testimoni/andar-500h.png"
      name="Andar Wahyu Nugroho"
      role="KRAPYAK - PUSKUD SEMARANG"
    ></TestimonialCard>,
    <TestimonialCard
      picture_src="/assets/testimoni/andar-500h.png"
      rootClassName="rootClassName1"
      image_src="/assets/testimoni/linda-500h.png"
      name="Linda Febby Kusumaningrum"
      role="JUWIRING - KUD MARDI RAHAYU"
    ></TestimonialCard>
  ]
  return (
    <div className="home-container">
      <Helmet>
        <title>Lini Mitra</title>
        <meta
          name="description"
          content="Platform yang menyediakan produk sembako &amp; kebutuhan rumah tangga lainnya yang selalu dibeli oleh toko di sekitar kamu."
        />
        <meta property="og:title" content="Lini Mitra" />
        <meta
          property="og:description"
          content="Platform yang menyediakan produk sembako &amp; kebutuhan rumah tangga lainnya yang selalu dibeli oleh toko di sekitar kamu."
        />
      </Helmet>
      <div className="home-main">
        <div className="home-blur-background"></div>
        <div className="home-hero">
          <div className="home-container01">
            <h3 className="home-text">Dapatkan Puluhan Juta Setiap Bulan</h3>
            <h1 id="title-box" className="home-text01">
              TANPA MODAL
            </h1>
            <PrimaryButton onClick={() => {
              window.open('https://mitra.linistore.id/register', '_blank');
              return null
            }} button="GABUNG LINI MITRA"></PrimaryButton>
          </div>
          <img
            alt="image"
            src="/assets/mockup-phone-500w.png"
            className="home-image"
          />
        </div>
        <img
          alt="image"
          src="/turquoise-circle.svg"
          className="home-turquoise-cirble"
        />
        <img
          alt="image"
          src="/purple-circle.svg"
          className="home-purple-circle"
        />
        <img alt="image" src="/left.svg" className="home-left" />
        <img alt="image" src="/right.svg" className="home-right" />
      </div>
      <div className="home-clients">
        <div className="home-divider"></div>
        <div className="home-container02">
          <h2 className="home-text02">
            <span className="Headline3 home-text03">Apa Aja Produk di</span>
            <br></br>
          </h2>
          <h1 className="home-text05">LINI MITRA</h1>
        </div>
        <div className="home-container03">
          <img
            alt="image"
            src="/assets/products/coca-cola-200h.png"
            className="home-image01"
          />
          <img
            alt="image"
            src="/assets/products/chocolatos-200h.webp"
            className="home-image02"
          />
          <img
            alt="image"
            src="/assets/products/cleo-200h.png"
            className="home-image03"
          />
          <img
            alt="image"
            src="/assets/products/garam-200w.png"
            className="home-image04"
          />
          <img
            alt="image"
            src="/assets/products/indomie-200h.png"
            className="home-image05"
          />
          <img
            alt="image"
            src="/assets/products/le_minerale-200h.webp"
            className="home-image06"
          />
          <img
            alt="image"
            src="/assets/products/mitra-200h.webp"
            className="home-image07"
          />
          <img
            alt="image"
            src="/assets/products/molto-200h.webp"
            className="home-image08"
          />
          <img
            alt="image"
            src="/assets/products/rinso-200h.webp"
            className="home-image09"
          />
          <img
            alt="image"
            src="/assets/products/sedaap-200h.png"
            className="home-image10"
          />
          <img
            alt="image"
            src="/assets/products/teh-pucuk-200h.png"
            className="home-image11"
          />
        </div>
        <div className="home-container04">
          <span className="home-text06">Dan 1.000+ Produk Lainnya</span>
        </div>
        <div className="home-divider1"></div>
      </div>
      <div className="home-cta">
        <div className="home-container05">
          <div className="home-container06">
            <h1 className="home-text07">Halo, Kita <br></br>LINI MITRA</h1>
            <span className="home-text08">
              <span>
                Platform yang menyediakan produk sembako &amp; kebutuhan rumah
                tangga lainnya yang selalu dibeli oleh toko di sekitar kamu.
              </span>
              <br></br>
              <br></br>
              <span>
                Biasanya keuntungan dari jualan produk sembako ini cuman 2-4%,
                nah, LINI MITRA bisa kasih kamu
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text13">KEUNTUNGAN SAMPAI DENGAN 20%</span>
              <span className="home-text14">.</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>Kebayang….. Kan??!</span>
              <br></br>
              <br></br>
              <span>
                Jualan Sembako dan produk lainnya yang pasti dibeli masyarakat
                dan rumahan yang bikin kamu bisa dapetin komisi puluhan juta
                rupiah
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="home-benefits">
        <h2 className="home-text23">
          <span className="Headline3 home-text24">
            Benefit Bergabung
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
        </h2>
        <h1 className="home-text26">LINI MITRA</h1>
        <div className="home-container08">
          <div className="home-container09">
            <FeatureCard
              image_src="/assets/icons/flexibel.svg"
              card_title="Bisa Berjualan Kapanpun"
              rootClassName="feature-card-root-class-name"
            ></FeatureCard>
            <FeatureCard
              image_src="/assets/icons/no-money.svg"
              card_title="Tidak Perlu Modal"
              rootClassName="feature-card-root-class-name1"
            ></FeatureCard>
          </div>
          <div className="home-container10">
            <FeatureCard
              image_src="/assets/icons/smartphone.svg"
              card_title="Hanya Perlu Smartphone"
              rootClassName="feature-card-root-class-name2"
            ></FeatureCard>
            <FeatureCard
              image_src="/assets/icons/dropship.svg"
              card_title="Fasilitas Dropship TANPA Stok"
              rootClassName="feature-card-root-class-name3"
            ></FeatureCard>
          </div>
          <div className="home-container11">
            <FeatureCard
              image_src="/assets/icons/delivery.svg"
              card_title="Tidak Perlu Pusing Pengiriman"
              rootClassName="feature-card-root-class-name4"
            ></FeatureCard>
            <FeatureCard
              image_src="/assets/icons/meeting.svg"
              card_title="Live Zoom Meeting Setiap Minggu"
              rootClassName="feature-card-root-class-name5"
            ></FeatureCard>
          </div>
          <div className="home-container12">
            <FeatureCard
              image_src="/assets/icons/reseller.svg"
              card_title="Bisa Rekrut Pasukan Reseller"
              rootClassName="feature-card-root-class-name6"
            ></FeatureCard>
            <FeatureCard
              image_src="/assets/icons/cod.svg"
              card_title="Bisa COD"
              rootClassName="feature-card-root-class-name7"
            ></FeatureCard>
          </div>
          <div className="home-container13">
            <FeatureCard
              image_src="/assets/icons/transfer.svg"
              card_title="Komisi Bisa Langsung Dicairkan Setelah Transaksi Selesai"
              rootClassName="feature-card-root-class-name8"
            ></FeatureCard>
          </div>
        </div>
        <PrimaryButton
          onClick={() => {
            window.open('https://mitra.linistore.id/register', '_blank');
            return null
          }}
          button="AYO, GABUNG LINI MITRA"
          rootClassName="primary-button-root-class-name"
        ></PrimaryButton>
      </div>
      <div className="home-testimonial">
        <div className="home-container14">
          <h1 className="home-text27">Bukti Kesuksesan Member</h1>
          <div className="home-container15">
            <AliceCarousel
              autoHeight
              autoPlay
              autoPlayInterval={5000}
              infinite
              mouseTracking
              disableDotsControls
              disableButtonsControls
              items={testimonialItems} />
          </div>
        </div>
      </div>
      <div className="home-achievements">
        <div className="home-container16">
          <h2 className="home-text28">Kami Hadir Dengan Yang Terbaik</h2>
          <span className="home-text29">
            Bisnis Tanpa Modal, Tanpa Resiko, Gampang Dijual dan Pasti Laku
          </span>
        </div>
        <div className="home-container17">
          <div className="home-container18">
            <StatsCard
              number="1,000+"
              image_src="/assets/icons/products.svg"
              description="Produk Siap Jual"
            ></StatsCard>
          </div>
          <div className="home-container19"></div>
        </div>
        <span className="home-text30">
          <span>Berbagai Kategori yang tersedia di Lini Mitra</span>
          <br></br>
        </span>
        <div className="home-container20">
          <div className="home-container21">
            <span className="home-text33">Sembako</span>
          </div>
          <div className="home-container22">
            <span className="home-text34">Obat</span>
          </div>
          <div className="home-container23">
            <span className="home-text35">Elektronik</span>
          </div>
          <div className="home-container24">
            <span className="home-text36">Kosmetik</span>
          </div>
          <div className="home-container25">
            <span className="home-text37">Makanan &amp; Minuman</span>
          </div>
          <div className="home-container26">
            <span className="home-text38">
              <span className="home-text39">Peralatan Rumah Tangga</span>
            </span>
          </div>
          <div className="home-container27">
            <span className="home-text41">
              <span>Dan 20+ Kategori Lainnya</span>
              <br></br>
            </span>
          </div>
        </div>
        <PrimaryButton
          onClick={() => {
            window.open('https://mitra.linistore.id/register', '_blank');
            return null
          }}
          button="AYO, GABUNG LINI MITRA"
          rootClassName="primary-button-root-class-name1"
        ></PrimaryButton>
      </div>
      <div className="home-footer">
        <footer className="home-container28">
          <img
            alt="image"
            src="/assets/logo-text-200h.png"
            className="home-image14"
          />
          <div className="home-container29">
            <span className="home-text44">
              <span className="Body2">
                ©
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>PT Retail Kita Indonesia</span>
              <br></br>
              <span className="home-text48">AXA Tower Lt 28 Suite 06</span>
              <br className="home-text49"></br>
              <span className="home-text50">Kuningan. Setiabudi</span>
              <br className="home-text51"></br>
              <span className="home-text52">Jakarta Selatan</span>
              <br></br>
            </span>
          </div>
          <div className="home-container30">
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link7"
            >
              <div className="home-container31">
                <svg viewBox="0 0 877.7142857142857 1024" className="home-icon">
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <span className="home-text54">retailkita_id</span>
              </div>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link8"
            >
              <div className="home-container32">
                <svg viewBox="0 0 1024 1024" className="home-icon2">
                  <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path>
                </svg>
                <span className="home-text55">retailkita.id</span>
              </div>
            </a>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
